export var TEST_TYPE = {
  practice: '1',
  exercise: '2',
  exam: '3'
};
export var TEST_MODE = {
  "public": '1',
  "private": '2'
};
export var STATUS_ASSIGNMENT = {
  PROCESSING: '1',
  UPCOMING: '5',
  ONGOING: '6',
  ENDED: '7',
  CANCELED: '2',
  ACTIVE: '3',
  PASS: '10',
  FAIL: '11'
};
export var TIME_UNIT = {
  MINUTE: 'minute',
  HOUR: 'hour',
  DAY: 'day'
};
export var cheatingRules = [{
  value: '1',
  content: 'Chụp khuôn mặt trước khi làm bài'
}, {
  value: '2',
  content: 'Không được chuyển tab'
}, {
  value: '3',
  content: 'Không được Copy / Paste nội dung'
}, {
  value: '4',
  content: 'Không được thoát chế độ toàn màn hình'
}, {
  value: '5',
  content: 'Bắt buộc luôn mở Camera'
}, {
  value: '6',
  content: 'Bắt buộc luôn mở Microphone'
}, {
  value: '7',
  content: 'Lưu hoạt động tiếng và hình bất thường'
}];
export var DATE_TIME_FORMAT = "DD/MM/YYYY - HH:mm";